<template>
    <div>
      <v-row class="pt-5">
        <v-col cols="12">
          <div class="d-flex align-items-center">
            <svg width="20" height="20" viewBox="0 0 28 28">
                <g id="Grupo_3815" data-name="Grupo 3815" transform="translate(-418 -410)">
                  <g id="Elipse_146" data-name="Elipse 146" transform="translate(418 410)"
                      fill="#fff" stroke="#057aff" stroke-width="1.5">
                    <circle cx="14" cy="14" r="14" stroke="none" />
                    <circle cx="14" cy="14" r="13.25" fill="none" />
                  </g>
                  <text id="_1" data-name="1" transform="translate(427.5 430.988)" fill="#1a1a1a"
                        font-size="18" font-family="OpenSans-Regular, Open Sans">
                    <tspan x="0" y="0">1</tspan>
                  </text>
                </g>
            </svg>
            <p class="fs16 bold ml-2 mb-0">{{ $t('analisis.txt_4') }}</p>
          </div>
        </v-col>
        <v-radio-group
          v-model="tipoInterpretacion"
          row
          @change="changeTipoInterpretacion()"
          class="px-3 mt-3">
          <v-radio
            v-for="(radio, index) in actionRadios" :key="index"
            class="fs16"
            :label="radio.text"
            :value="radio.value"
          >
          </v-radio>
        </v-radio-group>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="d-flex align-items-center">
            <p class="fs16 bold ml-2 mb-0">{{ $t('analisis.txt_3') }}</p>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="6" md="5" sm="12">
          <v-select
            v-model="equipoLaboratorioSelected"
            :items="equipos_laboratorio"
            item-text="inlaca_equipo.equipo"
            item-value="equipo_id"
            :placeholder="$t('analisis.Selecciona')"
            append-icon="mdi-chevron-down"
            return-object
            @change="changeEquipments()"
            outlined
            dense
          ></v-select>
        </v-col>
      </v-row>
      <template v-if="tipoInterpretacion === 3">
        <v-row>
          <v-col class="pt-0 dflex" cols="6" md="5" sm="12">
            <v-text-field
                v-model="bitacora_id"
                :placeholder="$t('analisis.ingresa_numero_bitacora')"
                type="number"
                onkeydown="return
              event.keyCode !== 69"
                v-on:keyup.enter="false"
                min="1"
                outlined
                dense
                :disabled="!equipoLaboratorioSelected"
            >
            </v-text-field>
            <v-btn
                style="padding: 1.1rem 1rem !important;"
                class="btnot" color="primary"
                @click="getBitacora()"
                :disabled="bitacora_id === null || bitacora_id < 1"
            >
              {{ $t('analisis.revisar_ot') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-if="tipoInterpretacion === 4">
        <v-row>
          <v-col
              cols="6" md="5" sm="12">
            <v-select
                v-model="programaSelected"
                :items="programas_taag"
                item-text="programa"
                item-value="id"
                :placeholder="$t('analisis.selecciona_programa')"
                append-icon="mdi-chevron-down"
                @change="changePrograma()"
                outlined
                dense
            ></v-select>
          </v-col>
        </v-row>
      </template>

      <v-row v-if="maxFiles > 0">
          <v-col cols="6" md="5" sm="12" class="text-left py-0 my-0">
            <span>{{ this.$t('analisis.must_select') }} <span class="font-weight-bold">{{ maxFiles }}</span> {{this.$t('analisis.files')}}</span>
          </v-col>
        </v-row>
      <v-row>
        <v-col cols="6" md="5" sm="12" class="c-pointer o-file-search">
            <v-file-input v-model="uploadFiles" :placeholder="$t('analisis.carga')"
              chips
              :disabled="maxFiles === 0"
              :multiple="maxFiles > 1"
              @change="checkFile()"
              ref="fileInput"
              prepend-icon=""
              outlined :clearable="true" class="o-file-search__input">
            </v-file-input>
            <span class="z999  o-file-search__icon2">
              <svg width="19.5" height="19.5" viewBox="0 0 19.5 19.5">
                  <g id="upload_2_" data-name="upload (2)" transform="translate(0.75 0.75)">
                      <path id="Trazado_8521" data-name="Trazado 8521"
                            d="M21,15v4a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V15" transform="translate(-3 -3)"
                            fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="1.5" />
                      <path id="Trazado_8522" data-name="Trazado 8522" d="M17,8,12,3,7,8"
                            transform="translate(-3 -3)" fill="none" stroke="#fff"
                            stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                      <line id="Línea_11" data-name="Línea 11" y2="12" transform="translate(9)"
                            fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="1.5" />
                  </g>
              </svg>
            </span>
          </v-col>
        <v-col v-if="tipoInterpretacion === 4" cols="2" md="3" sm="12">
          <v-btn depressed color="#007AFF" class="bold c-btn white--text"
                 @click="getFilesRequiredBitacoraManual()">
            {{ $t('analisis.calcular_archivos') }}
          </v-btn>
        </v-col>
        </v-row>
      <v-row v-if="archivos_requeridos">
          <v-col cols="6" md="6" sm="12">
            <v-list subheader dense color="#f6f8fa">
              <v-list-item class="dense-list-item" color="#f6f8fa" v-for="(fileReq, indexFile) in archivos_requeridos" :key="indexFile">
                <v-list-item-icon>
                  <v-icon :color="fileReq.file_uploaded ? 'success' : 'error'">
                    {{fileReq.file_uploaded ? 'mdi-check-bold' : 'mdi-close-thick'}}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{fileReq.file_uploaded ? fileReq.file_uploaded : `{ alphanumeric 1 a 60 characters }${fileReq.file_name}`}}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon small v-if="fileReq.file_uploaded" @click="deleteFile(indexFile,fileReq.file_uploaded)">
                    <v-icon color="grey lighten-1">mdi-file-remove-outline</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>

      <v-row>
        <v-col
          cols="6" md="5" sm="12" class="pb-3">
        </v-col>
        <v-col class="pr-3 dflex btn-analisis c-botones text-left">
          <v-btn depressed color="#007AFF" class="bold c-btn white--text"
            @click="getInterpretation()"
            :disabled="false">{{ $t('analisis.analizar_resultados') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
</template>
<script>
import Swal from 'sweetalert2';
import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      unvalid_files: [],
      actionRadios: [
        { value: 3, text: this.$t('analisis.bitacora') },
        { value: 4, text: this.$t('analisis.analisis_sin_muestra') },
      ],
      uploadFiles: [],
    };
  },
  computed: {
    ...mapState('storeInterpretacion', [
      'equipos_laboratorio', 'isLab', 'maxFiles', 'archivos_requeridos', 'pocillos_interpretacion',
      'configPocillos', 'pocillos_bitacora', 'niveles_muestras', 'medios_ensayos', 'ensayos_software',
      'matrices', 'tipo_deteriorantes', 'controles', 'programa_id', 'programaSelected', 'programas_taag',
      'sampleTemplate', 'canalesSoftwares', 'softwares', 'mixes',
    ]),
    tipoInterpretacion: {
      get() { return this.$store.state.storeInterpretacion.tipoInterpretacion; },
      set(value) { this.actionTipoInterpretacion(value); },
    },
    equipoLaboratorioSelected: {
      get() { return this.$store.state.storeInterpretacion.equipoLaboratorioSelected; },
      set(value) { this.actionEquipoLaboratorioSelected(value); },
    },
    bitacora_id: {
      get() { return this.$store.state.storeInterpretacion.bitacora_id; },
      set(value) { this.actionBitacora_id(value); },
    },
    selectedFiles: {
      get() { return this.$store.state.storeInterpretacion.selectedFiles; },
      set(value) { this.actionSelectedFiles(value); },
    },
    programaSelected: {
      get() { return this.$store.state.storeInterpretacion.programaSelected; },
      set(value) { this.actionProgramaSelected(value); },
    },
    getMix() {
      return (mix_id) => {
        let mixTxt = '-';
        const findMix = this.mixes.find((mix) => mix.mix_id == mix_id);
        console.log({ findMix, mix_id });
        if (findMix) {
          mixTxt = findMix.mixname;
        }
        return mixTxt;
      };
    },
  },
  methods: {
    ...mapActions('storeInterpretacion', ['actionLoadingModule', 'actionBitacoraData',
      'actionResetBitacoraData', 'actionMaxFiles', 'actionTipoInterpretacion',
      'actionEquipoLaboratorioSelected', 'actionSelectedFiles', 'actionBitacora_id',
      'actionPocillos_interpretacion', 'actionPrograma_id', 'actionShowAnalisis', 'actionFilesNamesSystem',
      'actionVersionFile', 'actionProgramaSelected', 'actionArchivos_requeridos', 'actionPocilloDinamic',
      'actionInterpretecionId']),
    changeTipoInterpretacion() {
      this.actionEquipoLaboratorioSelected(null);
      this.actionBitacora_id(null);
      this.actionSelectedFiles([]);
      this.actionMaxFiles(0);
      this.actionPocillos_interpretacion([]);
      this.actionResetBitacoraData();
      this.actionProgramaSelected(null);
      this.actionPocilloDinamic(null);
    },
    async changeEquipments() {
      console.log({ changeEquipments: this.equipoLaboratorioSelected });
      this.actionBitacora_id(null);
      this.actionSelectedFiles([]);
      this.actionMaxFiles(0);
      this.actionPocillos_interpretacion([]);
      this.actionResetBitacoraData();
      this.actionProgramaSelected(null);
      this.actionPocilloDinamic(null);
    },
    async changePrograma() {
      console.log({ changePrograma: this.programaSelected });
      this.actionSelectedFiles([]);
      this.actionMaxFiles(0);
      this.actionPocillos_interpretacion([]);
      this.actionArchivos_requeridos(null);
      this.actionPocilloDinamic(this.getSampleTemplate());
      await this.createPocillos();
    },
    async getBitacora() {
      this.actionSelectedFiles([]);
      this.actionMaxFiles(0);
      this.actionResetBitacoraData();
      await this.createPocillos();
      await this.searchBitacora().then(async (response) => {
        const informacion_bitacora = response.data.data.info_bitacora;
        this.actionPrograma_id(informacion_bitacora.programa_id);
        // 1 parcial, 2 completada
        if ([1, 2].includes(parseInt(informacion_bitacora.ind_status_bitacora))) {
          new Promise(async (resolve, reject) => {
            Swal.fire({
              title: this.$t('analisis.are_you_sure_overwrite_them'),
              text: '',
              showDenyButton: true,
              icon: 'warning',
              confirmButtonText: this.$t('analisis.yes_overwrite'),
              denyButtonText: this.$t('analisis.cancelar'),
            }).then((result) => {
              console.log({ result });
              if (result.isConfirmed) {
                resolve(true);
              } else if (result.isDenied) {
                reject(false);
              }
            });
          }).then(async (responseConfirm) => {
            console.log({ da: response.data.data });
            await this.actionBitacoraData(response.data.data);
            await this.rellenarPocillos();
          }).catch(async (error) => {
            console.error({ getBitacora_confirm: error });
            this.actionBitacora_id(null);
            this.actionResetBitacoraData();
          });
        } else {
          await this.actionBitacoraData(response.data.data);
          await this.rellenarPocillos();
          console.log({ pocillos_interpretacion: this.pocillos_interpretacion });
        }
      }).catch((error) => {
        switch (error.message) {
          case 'not_found_bitacora':
            Swal.fire({
              icon: 'warning',
              title: 'Warning',
              text: this.$t('analisis.not_found_bitacora'),
            });
            break;
          case 'some_assays_cant_use_equipment':
            Swal.fire({
              icon: 'warning',
              title: 'Warning',
              text: this.$t('analisis.some_assays_cant_use_equipment'),
            });
            break;
          case 'not_found_canales':
            Swal.fire({
              icon: 'warning',
              title: 'Warning',
              text: this.$t('analisis.not_found_canales'),
            });
            break;
          case 'some_assays_has_not_software_to_this_equipment':
            Swal.fire({
              icon: 'warning',
              title: 'Warning',
              text: this.$t('analisis.some_assays_has_not_software_to_this_equipment'),
            });
            break;
          default:
            console.log({ error });
            Swal.fire({
              icon: 'warning',
              title: 'Error',
              text: this.$t('analisis.an_error_occurred'),
            });
            break;
        }
        this.actionBitacora_id(null);
      })
        .finally(() => {
          this.actionLoadingModule();
        });
    },
    async searchBitacora() {
      this.actionLoadingModule({ text: this.$t('analisis.buscando_bitacora'), active: true });
      this.actionResetBitacoraData();
      return new Promise(async (resolve, reject) => {
        await axios.post(`${process.env.VUE_APP_URL_API}api/get-data-bitacora`, {
          bitacora_id: this.bitacora_id,
          equipo_id: this.equipoLaboratorioSelected.equipo_id,
        }, {
          headers: {
            Authorization: `Bearer ${this.$ls.storage.token}`,
          },
        }).then((response) => {
          try {
            if (response.data.status === 'fail') {
              throw new Error(response.data.error);
            }
            resolve(response);
          } catch (error) {
            reject(error);
          }
        })
          .catch((error) => {
            try {
              throw new Error(error.response.data.error);
            } catch (error) {
              reject(error);
            }
          });
      });
    },
    async getInterpretation() {
      if (this.tipoInterpretacion === 4) {
        this.actionLoadingModule({ text: this.$t('analisis.verificando_pocillos'), active: true });
        const pocillosVerficados = await this.verificarMuestras().then(() => true).catch(() => false);
        if (!pocillosVerficados) {
          return;
        }
      }
      this.unvalid_files = [];
      this.actionLoadingModule({ text: this.$t('analisis.validando_archivos_subidos'), active: true });
      await this.validateFilesInterpretacion()
        .then(async () => {
          await this.interpretation().then((response) => {
            console.log({ response });
            const pocillos_originales = structuredClone(this.pocillos_interpretacion);
            try {
              if (!response.data.data.pocillos || !response.data.data.pocillos.length) { throw new Error(this.$t('analisis.resultados_pocillos_empty')); }
              const pocillos_con_resultados = response.data.data.pocillos;
              console.log({ pocillos_con_resultados });
              this.actionFilesNamesSystem(response.data.data.files_names_system);
              this.actionVersionFile(response.data.data.versionFile);
              this.actionInterpretecionId(response.data.data.id_interpretacion);
              this.actionPocillos_interpretacion(pocillos_con_resultados);
              this.actionShowAnalisis(false);
            } catch (errRes) {
              this.actionPocillos_interpretacion(pocillos_originales);
              this.actionShowAnalisis(true);
              console.error({ errRes });
              throw new Error(this.$t('analisis.error_get_results_interpretacion'));
            }
          }).catch((error) => {
            console.error({ interpretationPromiseErr: error });
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.message,
            });
          }).finally(() => {
            this.actionLoadingModule();
          });
        })
        .catch((err) => {
          console.log({ unvalid_file: this.unvalid_files });
          Swal.fire({
            icon: 'warning',
            title: 'Warning',
            text: err.message,
          });
        }).finally(() => {
          this.actionLoadingModule();
        });
    },
    async interpretation() {
      this.actionLoadingModule({ text: this.$t('analisis.interpretando'), active: true });
      return new Promise(async (resolve, reject) => {
        const formData = new FormData();
        for (const file of this.selectedFiles) {
          formData.append('file', file, file.name);
        }
        formData.append('bitacora_id', this.bitacora_id);
        formData.append('equipo_id', this.equipoLaboratorioSelected.equipo_id);
        formData.append('pocillos', JSON.stringify(this.pocillos_interpretacion));
        formData.append('tipo_interpretacion', this.tipoInterpretacion);
        formData.append('programa_id', this.tipoInterpretacion === 3 ? this.programa_id : this.programaSelected);
        await axios.post(`${process.env.VUE_APP_URL_API}api/get-interpretacion`, formData, {
          headers: {
            Authorization: `Bearer ${this.$ls.storage.token}`,
            'content-type': 'multipart/form-data',
          },
        }).then((response) => {
          try {
            if (response.data.status === 'fail') {
              throw new Error(response.data.error);
            }
            resolve(response);
          } catch (error) {
            reject(error);
          }
        })
          .catch((error) => {
            try {
              throw new Error(error.response.data.error);
            } catch (error) {
              reject(error);
            }
          });
      });
    },
    async checkFile() {
      if (this.uploadFiles.length > this.maxFiles && this.maxFiles > 0) {
        this.unvalid_files = [];
        Swal.fire({
          icon: 'warning',
          title: 'Warning',
          text: `${this.$t('analisis.no_puede_cargar_mas_de')} ${this.maxFiles} ${this.$t('analisis.files')}`,
        });
        this.uploadFiles = [];
        // this.actionSelectedFiles([]);
        this.$refs.fileInput.reset();
        return;
      }
      if (this.uploadFiles.length) {
        this.unvalid_files = [];
        for await (const file of this.uploadFiles) {
          // console.log({file});
          const validFileIndex = await this.archivos_requeridos.findIndex((reqFile) => {
            const regexFile = new RegExp(reqFile.regex_js, 'g');
            return regexFile.test(file.name) && reqFile.tipo_file === file.type;
          });
          console.log({ validFileIndex });
          if (validFileIndex !== -1) {
            const file_required = this.archivos_requeridos[validFileIndex];
            const fileSelectedIndex = await this.selectedFiles.findIndex((fileSelec) => {
              const regexFile = new RegExp(file_required.regex_js, 'g');
              return regexFile.test(fileSelec.name) && fileSelec.type === file_required.tipo_file;
            });
            file_required.file_uploaded = file.name;
            if (fileSelectedIndex !== -1) {
              this.selectedFiles[fileSelectedIndex] = file;
            } else {
              this.selectedFiles.push(file);
            }
          } else {
            this.unvalid_files.push(structuredClone(file.name));
          }
        }
        console.log({ unvalid_files: this.unvalid_files });
        this.uploadFiles = [];
        this.$refs.fileInput.reset();
        console.log({ unvalid_files1: this.unvalid_files });
        console.log({ selec: this.selectedFiles });
        console.log({ archvosreq: this.archivos_requeridos });
        if (this.unvalid_files.length > 0) {
          console.log('entra');
          Swal.fire({
            icon: 'warning',
            title: this.$t('analisis.archivos_invalidos'),
            text: `${this.$t('analisis.archivos_invalidos')}: ${this.unvalid_files.join()}`,
          });
        }
      }
    },
    async deleteFile(indexFileRequerido, file_uploaded) {
      try {
        const fileSelectedIndex = await this.selectedFiles.findIndex((fileSelec) => fileSelec.name === file_uploaded);
        if (fileSelectedIndex !== 1) {
          this.selectedFiles.splice(fileSelectedIndex, 1);
          this.archivos_requeridos[indexFileRequerido].file_uploaded = null;
        }
      } catch (err) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: `${this.$t('analisis.error_eliminar_archivo')} ${this.file_uploaded}`,
        });
      }
    },
    async validateFilesInterpretacion() {
      return new Promise(async (resolve, reject) => {
        try {
          console.log({ selectedFiles: this.selectedFiles });
          console.log({ archivos_requeridos: this.archivos_requeridos });
          if (!this.archivos_requeridos) { throw new Error(this.$t('analisis.no_files_required')); }
          if (!this.selectedFiles.length) { throw new Error(this.$t('analisis.no_files_required')); }
          if (this.selectedFiles.length !== this.maxFiles) { throw new Error(this.$t('analisis.no_all_files_uploaded')); }
          for await (const file of this.selectedFiles) {
            // console.log({file});
            const validFile = this.archivos_requeridos.some((reqFile) => {
              const regexFile = new RegExp(reqFile.regex_js, 'g');
              // console.log({regexFile,TEST:regexFile.test(file.name),type_req:reqFile.tipo_file,file_type:file.type});
              return regexFile.test(file.name) && reqFile.tipo_file === file.type;
            });
            // console.log({validFile})
            if (!validFile) { this.unvalid_files.push(file); }
          }
          if (this.unvalid_files.length) {
            throw new Error(this.$t('analisis.has_invalid_file'));
          }
          resolve(true);
        } catch (error) {
          reject(error);
        }
      });
    },
    getCombinationLetters(letras, numeros) {
      const combinaciones = [];
      for (let i = 0; i < letras.length; i++) {
        for (let j = 0; j < numeros.length; j++) {
          combinaciones.push(letras[i] + numeros[j]);
        }
      }
      combinaciones.sort((a, b) => {
        const numA = parseInt(a.substring(1));
        const numB = parseInt(b.substring(1));
        const comp = numA - numB;
        if (comp !== 0) { return comp; }
        return a[0].localeCompare(b[0]);
      });
      return combinaciones;
    },
    getSampleTemplate() {
      return structuredClone(this.sampleTemplate);
    },
    async createPocillos() {
      this.actionPocillos_interpretacion([]);
      let coordPosition = 0;
      let sample = {};
      const pocillos = [];
      if (!this.equipoLaboratorioSelected) {
        this.actionProgramaSelected(null);
        Swal.fire({
          icon: 'warning',
          title: 'Warning',
          text: 'no_select_equipo',
        });
        return;
      }
      const configPocillo = this.configPocillos.find((config) => config.equipo_id === this.equipoLaboratorioSelected.equipo_id);
      if (!configPocillo) { return; }
      for await (const coord of this.getCombinationLetters(configPocillo.letters, configPocillo.coords)) {
        coordPosition++;
        sample = {};
        sample = this.getSampleTemplate();
        sample.coordinate = coord.trim();
        sample.position = coordPosition;
        pocillos.push(sample);
      }
      this.actionPocillos_interpretacion(pocillos);
    },
    async rellenarPocillos() {
      console.log({ pocillos_bitacora: this.pocillos_bitacora });
      for await (const muestraBitacora of this.pocillos_bitacora) {
        let coordSampleBitacora = this.equipoLaboratorioSelected.equipo_id == 1 ?
            this.valor_numerico_pocillo(muestraBitacora.sn_pozo) : muestraBitacora.sn_pozo;
        console.log({equipo:this.equipoLaboratorioSelected.equipo_id,coordSampleBitacora});
        const pocillo = this.pocillos_interpretacion.find((pocillo) => pocillo.coordinate == coordSampleBitacora);
        if (!pocillo) {
          break;
        }
        const getMedios = this.medios_ensayos.find((medios) => medios.id === muestraBitacora.id_medio);
        const getEnsayo = structuredClone(this.ensayos_software).find((ensayo) => {
          if (muestraBitacora.int_control === null) {
            return typeof ensayo.ensayos_matriz.find((ensayo_matriz) => ensayo_matriz.matriz_id === muestraBitacora.id_matriz && ensayo_matriz.ensayo_id === muestraBitacora.ensayo_id) !== 'undefined';
          }
          // console.log(ensayo.grupo_ensayo_id, muestraBitacora.grupo_ensayo_id, muestraBitacora.int_control);
          return ensayo.grupo_ensayo_id === muestraBitacora.grupo_ensayo_id && muestraBitacora.int_control !== null;
        });
        console.log({ getEnsayo });
        const getMatriz = this.matrices.find((matriz) => matriz.id === muestraBitacora.id_matriz);
        let getControl = null;
        let ensayo_name = muestraBitacora.ensa_informe;
        let { ensayo_easypcr_id } = muestraBitacora;
        let { ensayo_id } = muestraBitacora;
        if (muestraBitacora.int_control !== null) {
          getControl = this.controles.find((control) => control.control_id === muestraBitacora.int_control);
          ensayo_name = getEnsayo.name_ensayo;
          ensayo_easypcr_id = getEnsayo.ensayos_matriz[0].ensayo_easypcr_id;
          ensayo_id = getEnsayo.ensayos_matriz[0].ensayo_id;
        }
        pocillo.ot =	muestraBitacora.ot_id;
        pocillo.medio_id =	muestraBitacora.id_medio;
        pocillo.medio_prefix = getMedios ? getMedios.prefijo : null;
        pocillo.medio_name = getMedios ? getMedios.nombre : null;
        pocillo.muestra_id =	muestraBitacora.muestra_id;
        pocillo.ensayo_id =	ensayo_id;
        pocillo.grupo_ensayo_id =	muestraBitacora.grupo_ensayo_id;
        pocillo.grupo_ensayo_mix =	`${muestraBitacora.grupo_ensayo_id}_${muestraBitacora.mixId}`;
        pocillo.ensayo_muestra_id =	muestraBitacora.ensayo_muestra_id;
        pocillo.ensayo_easypcr_id =	ensayo_easypcr_id;
        pocillo.matriz_id =	muestraBitacora.id_matriz;
        pocillo.matriz_name = getMatriz ? getMatriz.matriz : null;
        pocillo.descripcion =	muestraBitacora.muestra_name;
        pocillo.deteriorado =	muestraBitacora.deteriorante;
        pocillo.mix_id =	muestraBitacora.mixId;
        pocillo.mix_prefix =	muestraBitacora.mixname;
        pocillo.nivel_muestra =	muestraBitacora.nivel_muestra;
        pocillo.nivel_muestra_names =	muestraBitacora.nivel_muestra_names;
        pocillo.type_semicuantificado =	muestraBitacora.tipo_semicuantificado;
        pocillo.is_control =	muestraBitacora.int_control === null ? 0 : 1;
        pocillo.control_id =	muestraBitacora.int_control;
        pocillo.bitacora_muestra_id =	0;
        pocillo.uid =	muestraBitacora.uid;
        pocillo.parametros_interpretacion =	null;
        pocillo.txt_range = muestraBitacora.txtRange;
        pocillo.prefijo_muestra = muestraBitacora.int_control === null ? 'Mu' : getControl.control_prefix;
        pocillo.ensayo_name = ensayo_name;
        pocillo.zona = muestraBitacora.zona;
        pocillo.cliente_nombre = muestraBitacora.nombre;
        pocillo.local_nombre = muestraBitacora.nombre_local;
        pocillo.cliente_id_muestra = muestraBitacora.cliente_id_muestra;
        pocillo.control_muestra_ot = muestraBitacora.control_muestra_ot;
        console.log({pocillo});
      }
    },
    valor_numerico_pocillo(cadena) {
      let diccionario = { A: 1, B: 2, C: 3, D: 4, E: 5, F: 6, G: 7, H: 8 };
      let letra = cadena.charAt(0);
      let numero = parseInt(cadena.substr(1), 10) - 1;
      let valor_letra = diccionario[letra];
      let valor_numerico_total = valor_letra + numero * 8;
      return valor_numerico_total;
    },
    async getFilesRequiredBitacoraManual() {
      this.actionArchivos_requeridos(null);
      this.actionMaxFiles(0);

      const pocillosBitacora = structuredClone(this.pocillos_interpretacion);
      const pocillosLlenos = await pocillosBitacora
        .filter((pocillo) => pocillo.grupo_ensayo_id !== null
              && pocillo.mix_id !== null);
      if (!pocillosLlenos.length) {
        Swal.fire({
          icon: 'warning',
          title: this.$t('analisis.warning'),
          text: this.$t('analisis.no_muestras_bitacora'),
        });
        return;
      }
      let canales_not_found = [];
      let filesNecessary = [];
      let software_not_found = [];
      for await (const pocillo of pocillosLlenos) {
        const grupoEnsayoId = pocillo.grupo_ensayo_id;
        const mixId = pocillo.mix_id;
        const getCanalesNeeded = await this.canalesSoftwares
          .filter((canal) => canal.equipo_id === this.equipoLaboratorioSelected.equipo_id
                && canal.grupo_ensayo_id === grupoEnsayoId);
        if (!getCanalesNeeded.length) {
          canales_not_found.push(JSON.stringify({ equipo_id: this.equipoLaboratorioSelected.equipo_id, grupo_ensayo_id: grupoEnsayoId }));
        }
        for await (const canal of getCanalesNeeded) {
          const canalClone = structuredClone(canal);
          delete canalClone.grupo_ensayo_id;
          delete canalClone.equipo_id;
          filesNecessary.push(JSON.stringify(canalClone));
        }
        const getSoftwareEnsayos = await this.softwares
          .filter((soft) => soft.equipo_id === this.equipoLaboratorioSelected.equipo_id
                && soft.mix_id === mixId
                && soft.grupo_ensayo_id === grupoEnsayoId);
        if (!getSoftwareEnsayos.length) {
          software_not_found.push(JSON.stringify({ equipo_id: this.equipoLaboratorioSelected.equipo_id, grupo_ensayo_id: grupoEnsayoId }));
        }
      }

      canales_not_found = [...new Set(canales_not_found)].map((m) => JSON.parse(m));
      filesNecessary = [...new Set(filesNecessary)].map((m) => JSON.parse(m));
      software_not_found = [...new Set(software_not_found)].map((m) => JSON.parse(m));
      if (canales_not_found.length) {
        Swal.fire({
          icon: 'warning',
          title: this.$t('analisis.warning'),
          text: this.$t('analisis.some_assays_cant_use_equipment'),
        });
        return;
      }
      if (!filesNecessary.length) {
        Swal.fire({
          icon: 'warning',
          title: this.$t('analisis.warning'),
          text: this.$t('analisis.not_found_canales'),
        });
        return;
      }
      if (software_not_found.length) {
        Swal.fire({
          icon: 'warning',
          title: 'Warning',
          text: 'some_assays_has_not_software_to_this_equipment',
        });
        return;
      }
      this.actionArchivos_requeridos(filesNecessary);
      this.actionMaxFiles(filesNecessary.length);
    },

    // no traducir esta funcion
    async validExistControls() {
      const pocillosLlenos = this.pocillos_interpretacion.filter((pocillo) => pocillo.muestra_id !== null);
      if (pocillosLlenos.length === 0) {
        return { valid: false, res: 'not_samples' };
      }
      const samplesDisponibles = pocillosLlenos.filter((sample) => sample.grupo_ensayo_id > 0 && !sample.is_control);
      const getSamplesAssaysUsed = this.groupBy(samplesDisponibles, (sample) => sample.grupo_ensayo_mix);
      if (!(Object.keys(getSamplesAssaysUsed).length > 0)) {
        return { valid: false, res: 'no_samples_asigned' };
      }
      const samplesMedium = pocillosLlenos.filter((sample) => sample.is_control /* && sample.control === 'MC' */);
      if (samplesMedium.length) {
        const samplesMediumWithAssay = samplesMedium.filter((sample) => sample.grupo_ensayo_id === null || sample.grupo_ensayo_id === 0);
        if (samplesMediumWithAssay.length) return { valid: false, res: 'samples_medium_without_assays' };
      }

      const incompleteControls = [];
      for await (const grupoEnsayoId of Object.keys(getSamplesAssaysUsed)) {
        const splitGrupo = grupoEnsayoId.split('_');
        const grupo_ensayo_id = splitGrupo[0];
        const mix_id = splitGrupo[1];
        const objectIncomplete = {
          grupo_ensayo_mix: grupoEnsayoId, grupo_ensayo_id, mix_id, controlesFaltantes: [],
        };
        const controlesDisponibles = pocillosLlenos.filter((sample) => sample.grupo_ensayo_id > 0 && sample.is_control
            && sample.grupo_ensayo_id == grupo_ensayo_id && sample.mix_id == mix_id);
        console.log({ controlesDisponibles });
        if (controlesDisponibles.length === 0) {
          objectIncomplete.controlesFaltantes.push({ control: 'PC', type: 'control' });
          objectIncomplete.controlesFaltantes.push({ control: 'NC', type: 'control' });
          /* if(this.isLab){
            objectIncomplete.controlesFaltantes.push({control:'PEC',type: 'control'});
            objectIncomplete.controlesFaltantes.push({control:'NEC',type: 'control'});
          } */
          incompleteControls.push(objectIncomplete);
          continue;
        }
        const hasCP = controlesDisponibles.filter((sample) => sample.prefijo_muestra === 'PC');
        const hasCN = controlesDisponibles.filter((sample) => sample.prefijo_muestra === 'NC');
        const hasPEC = controlesDisponibles.filter((sample) => sample.prefijo_muestra === 'PEC');
        const hasNEC = controlesDisponibles.filter((sample) => sample.prefijo_muestra === 'NEC');

        if (!(hasCP.length > 0)) { objectIncomplete.controlesFaltantes.push({ control: 'PC', type: 'control' }); }
        if (!(hasCN.length > 0)) { objectIncomplete.controlesFaltantes.push({ control: 'NC', type: 'control' }); }
        /* if(this.isLab){
          if(!(hasPEC.length > 0)){ objectIncomplete.controlesFaltantes.push({control:'PEC',type: 'control'}); }
          if(!(hasNEC.length > 0)){ objectIncomplete.controlesFaltantes.push({control:'NEC',type: 'control'}); }
        } */
        if (objectIncomplete.controlesFaltantes.length > 0) {
          incompleteControls.push(objectIncomplete);
        }
      }
      // check samples mixes
      const getSamplesAssaysUsedGrupo = this.groupBy(samplesDisponibles, (sample) => sample.grupo_ensayo_id);
      for await (const _grupo_ensayo_id of Object.keys(getSamplesAssaysUsedGrupo)) {
        const getEnsayo = this.ensayos_software.filter((assay) => assay.grupo_ensayo_id == _grupo_ensayo_id);
        if (getEnsayo.length) {
          const mix_together = getEnsayo[0].control_interno == 0;
          const mixes_assay = this.mixes.filter((mix) => mix.grupo_ensayo_id == _grupo_ensayo_id && mix.id_programa == this.programaSelected);
          const group_mixes_assay = this.groupBy(mixes_assay, (mix) => mix.mix_id);
          const key_mixes = Object.keys(group_mixes_assay);
          console.log({ key_mixes, mix_together });
          const samplesGrupoEnsayo = this.groupBy(samplesDisponibles, (sample) => sample.ensayo_muestra_id);
          console.log({ samplesGrupoEnsayo });
          for await (const [_ensayo_muestra_id, muestras] of Object.entries(samplesGrupoEnsayo)) {
            const muestrasTipo = this.groupBy(muestras, (sample) => sample.type_semicuantificado);
            for await (const [_tipo_sample, _muestras] of Object.entries(muestrasTipo)) {
              const samplesMixes = this.groupBy(_muestras, (sample) => sample.mix_id);
              const keys_samplesMixes = Object.keys(samplesMixes);
              if (_muestras.length !== key_mixes.length && mix_together) {
                const intersectionMixes = key_mixes.filter((x) => !keys_samplesMixes.includes(x));
                if (intersectionMixes.length) {
                  console.log({ _tipo_sample, _muestras, intersectionMixes });
                  for await (const intersectionMix of intersectionMixes) {
                    const objectIncomplete = {
                      grupo_ensayo_mix: `${_grupo_ensayo_id}_${intersectionMix}`, grupo_ensayo_id: _grupo_ensayo_id, mix_id: intersectionMix, controlesFaltantes: [],
                    };
                    objectIncomplete.controlesFaltantes.push({ control: _muestras[0].descripcion, type: 'muestra', type_semi: this.getSemiCountType(_muestras[0]) });
                    incompleteControls.push(objectIncomplete);
                  }
                }
              }
            }
          }
        }
      }

      if (incompleteControls.length) {
        return { valid: false, res: 'incomplete_controls', controls: incompleteControls };
      }
      return { valid: true, res: 'complete', controls: null };
    },
    getSemiCountType(sample) {
      if (parseInt(sample.type_semicuantificado) === 0) {
        return 'Directa';
      }
      return sample.txtRango;
    },
    async hasControlsAssayNotUsed() {
      // console.warn('hasControlsAssayNotUsed');
      // aqui buscare entre todos los controles si alguno tiene un ensayo que no se usa entre las muestras
      const posillosUsados = this.pocillos_interpretacion.filter((pocillo) => pocillo.muestra_id !== null && pocillo.grupo_ensayo_id > 0 && !pocillo.is_control);
      // console.log({posillosUsados});
      const getSamplesAssaysUsed = this.groupBy(posillosUsados, (sample) => sample.grupo_ensayo_mix);
      const controlesUsados = this.pocillos_interpretacion.filter((pocillo) => pocillo.muestra_id !== null && pocillo.grupo_ensayo_id > 0 && pocillo.is_control);
      // console.log({controlesUsados});
      const getControlesAssaysUsed = this.groupBy(controlesUsados, (sample) => sample.grupo_ensayo_mix);
      // console.log({getSamplesAssaysUsed: Object.keys(getSamplesAssaysUsed)},{getControlesAssaysUsed: Object.keys(getControlesAssaysUsed)});
      const intersection = Object.keys(getControlesAssaysUsed).filter((x) => !Object.keys(getSamplesAssaysUsed).includes(x));
      const getControlsIncorrectos = this.pocillos_interpretacion.filter((sample) => intersection.includes(sample.grupo_ensayo_mix));
      return intersection.length > 0;
    },
    groupBy(xs, f) {
      return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
    },
    getDescByPrefix(control) {
      let txt = '';
      switch (control) {
        case 'NC': txt = this.$t('analisis.control_negativo_simbolo'); break;
        case 'PC': txt = this.$t('analisis.control_positivo_simbolo'); break;
        case 'PEC': txt = this.$t('analisis.cePositivo'); break;
        case 'NEC': txt = this.$t('analisis.ceNegativo'); break;
        case 'PMC': txt = this.$t('analisis.cMedio'); break;
        case 'NMC': txt = this.$t('analisis.cnMedio'); break;
      }
      return txt;
    },
    async verificarMuestras() {
      return new Promise(async (resolve, reject) => {
        const validControls = await this.validExistControls();
        console.log({ validControls });
        if (!validControls.valid) {
          let errorValidControls = '';
          switch (validControls.res) {
            case 'not_samples': errorValidControls = this.$t('analisis.not_samples'); break;
            case 'no_samples_asigned': errorValidControls = this.$t('analisis.no_samples_asigned'); break;
            case 'incomplete_controls': errorValidControls = this.$t('analisis.incomplete_controls'); break;
            case 'samples_medium_without_assays': errorValidControls = this.$t('analisis.samples_medium_without_assays'); break;
            default:
              errorValidControls = this.$t('analisis.not_samples');
          }
          let table = null;
          if (validControls.controls) {
            let tbody = '';
            validControls.controls.map((sample) => {
              const nameEnsa = this.ensayos_software.find((ensa) => ensa.grupo_ensayo_id == sample.grupo_ensayo_id);
              sample.controlesFaltantes.map((control) => {
                const txtControl = control.type === 'control' ? this.getDescByPrefix(control.control) : control.control;
                const txtType = control.type === 'control' ? '-' : control.type_semi;
                tbody
                    += `<tr>
                    <td class="o-modal-add-next-controls__table-td">${nameEnsa.name_ensayo}</td>
                    <td class="o-modal-add-next-controls__table-td">${txtControl}</td>
                    <td class="o-modal-add-next-controls__table-td">${this.getMix(sample.mix_id)}</td>
                    <td class="o-modal-add-next-controls__table-td">${txtType}</td>
                  </tr>`;
              });
            });
            table = `
                <div class='o-modal-add-next-controls__container'>
                  <table class='o-modal-add-next-controls__table'>
                    <thead>
                      <tr>
                        <th class='o-modal-add-next-controls__table-th'>${this.$t('analisis.ensayo')}</th>
                        <th class='o-modal-add-next-controls__table-th'>${this.$t('analisis.control')}</th>
                        <th class='o-modal-add-next-controls__table-th'>${this.$t('analisis.mix')}</th>
                        <th class='o-modal-add-next-controls__table-th'>${this.$t('analisis.tipo')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      ${tbody}
                    </tbody>
                  </table>
                </div>
              `;
          }
          this.actionLoadingModule();
          Swal.fire(
            {
              width: '600px',
              title: this.$t('analisis.warning'),
              html: table ? this.$t('analisis.next_controls_need_to_be_added') + table : errorValidControls,
              icon: 'warning',
              confirmButtonText: this.$t('analisis.confirmar_validacion_controles'),
              customClass: {
                container: 'o-modal-add-next-controls',
              },
            },
          );
          reject();
          return;
        }
        const controlesUsados = await this.hasControlsAssayNotUsed();
        if (controlesUsados) {
          Swal.fire({
            title: this.$t('analisis.warning'),
            text: this.$t('analisis.has_controls_with_assays_not_used'),
            icon: 'warning',
          });
          reject();
          return;
        }
        resolve();
      });
    },
  },
};
</script>
<style lang="scss">
  .dense-list-item{
    height: 40px !important;
  }
</style>
